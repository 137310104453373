import React from "react";
import {LaunchData} from "../components/next-launch/interfaces";

export interface FavoritesContextData {
  favorites: LaunchData[];
  updateFavorites: (item: LaunchData) => void,
}

const defaultValue: FavoritesContextData = {
  favorites: [],
  updateFavorites: (item: LaunchData) => {},
};

const FavoritesContext = React.createContext<FavoritesContextData>(defaultValue);

export default FavoritesContext;
