import React from 'react';
import PropTypes from 'prop-types';

interface ErrorProps {
  message: string;
}

const Error = ({message}: ErrorProps) => {

  return (
    <div className='error-message'>
      Error: {message}
    </div>
  )
};

Error.propTypes = {
  message: PropTypes.string.isRequired
};

export default Error;
