import {LaunchData, LaunchDataSimple} from "../../components/next-launch/interfaces";

export const getData = (name: string): LaunchData[]  => {
  const data = JSON.parse(localStorage.getItem(name) as string);
  return data || [];
};

export const setItem = (name: string, launch: LaunchData): boolean => {
  let success: boolean;
  try {
    let storageArray = [];
    const previousData = getData(name); //  Get previous items from storage
    if (previousData.length) {  //  Add previous data
      storageArray.push(...previousData);
    }
    //  Prepare new data
    const storageObject: LaunchDataSimple = {
      id: launch.id,
      name: launch.name,
      date_utc: launch.date_utc,
      date_precision: launch.date_precision,
      launchpad_name: launch.launchpad_name as string,
    };

    //  add new data
    storageArray.push(storageObject);
    //  Set local storage with new data
    localStorage.setItem(name, JSON.stringify(storageArray));

    success = true;
  } catch (e) {
    success = false;
  }

  return success;
};

export const removeItem = (name: string, item: LaunchData) => {
  const previousData = getData(name); //  Get previous data

  //  Remove item from previous data
  const newData: LaunchData[] = previousData.filter(launch => launch.id !== item.id);

  if (newData.length) { //  Set new data
    localStorage.setItem(name, JSON.stringify(newData));
  } else {  //  if there is no data left in storage remove whole item
    localStorage.removeItem(name)
  }
};
