import React, {useContext } from 'react';
import {LaunchData} from "../../next-launch/interfaces";
import PropTypes from "prop-types";
import starFull from '../../../assets/images/star-full.png';
import starEmpty from '../../../assets/images/star-empty.png';
import FavoritesContext from "../../../context/FavoritesContext";
import {formatDate} from "../../../_common/helpers/dateHelper";

interface ItemProps {
  item: LaunchData;
  withFavoriteButton: boolean;
}

const LaunchItem = ({item, withFavoriteButton}: ItemProps) => {
  const { favorites, updateFavorites } = useContext(FavoritesContext);

  //  add/remove favorite item from localStorage and context
  const toggleFavorite = (item: LaunchData): void => {
    updateFavorites(item);
  };

  const renderIcon = (item: LaunchData) => {
    const existing = favorites.filter(launch => launch.id === item.id);
    if (existing.length) {
      return <img style={{minHeight: 50}} onClick={() => toggleFavorite(item)} src={starFull} alt=""/>
    } else {
      return <img style={{minHeight: 50}} onClick={() => toggleFavorite(item)} src={starEmpty} alt=""/>
    }
  };

  return (
    <tr>
      <td>{item.name}</td>
      <td>{formatDate(item)}</td>
      <td>{item.launchpad_name}</td>
      {withFavoriteButton &&
        <td style={{minHeight: 100}}>{renderIcon(item)}</td>
      }
    </tr>
  )
};

LaunchItem.propTypes = {
  item: PropTypes.object.isRequired,
  withFavoriteButton: PropTypes.bool
};

LaunchItem.defaultProps = {
  withFavoriteButton: false
};

export default LaunchItem
