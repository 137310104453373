import React, {useEffect, useState} from 'react';
import {LaunchData} from "../next-launch/interfaces";
import LaunchService from "../../services/LaunchService";
import LaunchpadService from "../../services/LaunchpadService";
import Error from "../../_common/components/Error";
import LaunchItem from "./launch-item/LaunchItem";
import PropTypes from "prop-types";

interface UpcomingLaunchesProps {
  scrollToElement: () => void;
}

const UpcomingLaunches = ({scrollToElement}: UpcomingLaunchesProps) => {
  const [launches, setLaunches] = useState<LaunchData[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    getLaunches().then(() => setIsLoading(false));
  }, []);


  const getLaunches = async () => {
    try {
      //  Get upcoming launches
      const { data } = await LaunchService.upcoming();
      const copyData = [...data];
      //  Get launchpad data for each launch (needed to get launchpad name)
      await Promise.all(copyData.map(async (singleLaunch, index) => {
        const {data: launchpadData} = await LaunchpadService.get(singleLaunch.launchpad);
        //  add launchpad name
        copyData[index].launchpad_name = launchpadData.name;
      }));
      //  Set to state new array with launch data and launchpad name
      setLaunches(copyData);
    } catch (error) {
      setError('There was a problem with fetching data. Please try again');
    }
  };

  return (
    <div className="upcoming-launches">
      {isLoading &&
      <div className='loader-wrap' >
        <h1>STAND BY...</h1>
        <div className="loader">STAND BY...</div>
      </div>
      }
      {!isLoading && !error &&
      <>
        <h1>Upcoming: Next launches</h1>
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>Mission</th>
                <th>Date (UTC)</th>
                <th>Launchpad</th>
                <th>Favorite</th>
              </tr>
            </thead>
            <tbody>
             { launches && launches?.map((item, index) => {
              return (<LaunchItem withFavoriteButton item={item} key={index}/>)
            })}
            </tbody>
          </table>
        </div>
        <div style={{margin: 30}}>
          <i onClick={scrollToElement} className="arrow down"/>
        </div>
      </>
      }

      {!isLoading && error &&
      <Error message={error}/>
      }
    </div>
  );
};

UpcomingLaunches.propTypes = {
  scrollToElement: PropTypes.func
};

export default UpcomingLaunches;
