import ApiService from "./ApiService";
import axios from "axios";
import {LaunchData} from "../components/next-launch/interfaces";

 class LaunchService extends ApiService {

  next() {
    return axios.get<LaunchData>(this.baseUrl + 'launches/next')
  }

  upcoming() {
    return axios.get<LaunchData[]>(this.baseUrl + 'launches/upcoming')
  }

}

export default new LaunchService();
