import dayjs from "dayjs";
import {LaunchData} from "../../components/next-launch/interfaces";
import advancedFormat  from 'dayjs/plugin/advancedFormat';
import quarter  from 'dayjs/plugin/quarterOfYear';

dayjs.extend(advancedFormat);
dayjs.extend(quarter);

//  Format date based on `date_precision`
export const formatDate = (item: LaunchData) => {
  switch (item.date_precision) {
    case 'hour':
      return dayjs(item.date_utc).format('MMM Do YYYY, HH:mm');
    case 'day':
      return dayjs(item.date_utc).format('MMM Do YYYY');
    case 'month':
      return dayjs(item.date_utc).format('MMM YYYY');
    case 'quarter':
      return `Q${dayjs(item.date_utc).quarter()} ${dayjs(item.date_utc).format('YYYY')}`;

    default:
      return dayjs(item.date_utc).format('MMM Do YYYY, HH:mm')
  }
};
