import ApiService from "./ApiService";
import axios from "axios";
import {LaunchpadData} from "../components/next-launch/interfaces";

 class LaunchpadService extends ApiService {

   get(launchpadId: string) {
     return axios.get<LaunchpadData>(`${this.baseUrl}launchpads/${launchpadId}`)
   }

}

export default new LaunchpadService();
