import React, {useContext, useEffect, useState} from 'react';
import LaunchItem from "../upcoming-launches/launch-item/LaunchItem";
import FavoritesContext from "../../context/FavoritesContext";

const Favorites = () => {
  const { favorites } = useContext(FavoritesContext);


  return (
    <div className="upcoming-launches favorites">
      <h1>Favorites</h1>
      {favorites.length !== 0 &&
        <div className="container">
          <table>
            <thead>
            <tr>
              <th>Mission</th>
              <th>Date (UTC)</th>
              <th>Launchpad</th>
            </tr>
            </thead>
            <tbody>
            { favorites.length && favorites.map((item, index) => {
              return (<LaunchItem item={item} key={index}/>)
            })}
            </tbody>
          </table>
        </div>
      }
      {!favorites.length &&
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column'}}>
        <h1>You dont have any favorites yet</h1>
      </div>
      }
      {/* Add link to icons used for favorite button  just in case :) */}
      <div>
        <a target="_blank" href="https://icons8.com/icon/8ggStxqyboK5/star">Star</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
      </div>
    </div>
  );
};

export default Favorites;
