import React, {useEffect, useState} from 'react';
import LaunchService from "../../services/LaunchService";
import Error from "../../_common/components/Error";
import { LaunchData, TimeDifference } from './interfaces'
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import PropTypes from "prop-types";
import UpcomingLaunches from "../upcoming-launches/UpcomingLaunches";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";

dayjs.extend(duration);

interface NextLaunchProps {
  scrollToElement: () => void;
}

const NextLaunch = ({scrollToElement}: NextLaunchProps) => {
  const [launch, setLaunch] = useState<LaunchData | null>(null);
  const [timeToLaunch, setTimeToLaunch] = useState<TimeDifference>({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    LaunchService.next()
      .then(response => {
        console.log(window);
        setLaunch(response.data);

        // Add interval to recalculate time difference every second
        const interval = setInterval(() => {
          calculateDifference(response.data.date_local)
        }, 1000);
        return () => clearInterval(interval);

      })
      .catch(err => {
        // setError(err?.response?.data); // response error message
        setError('There was a problem with fetching data. Please try again'); // custom error message
        setIsLoading(false);
      })
  }, []);

  //  Calculate difference between now and launch date
  const calculateDifference = (time: string) => {
    const launchTime = dayjs(time); //  Create launch time date object
    const now = dayjs(); // Current date object
    const diff = launchTime.diff(now); // Calculate diff between two of them

    const days = dayjs.duration(diff).days(); //  Get diff days
    const hours = dayjs.duration(diff).hours(); //  Get diff hours
    const minutes = dayjs.duration(diff).minutes(); //  Get diff minutes
    const seconds = dayjs.duration(diff).seconds(); //  Get dif seconds
    setTimeToLaunch({ days, hours, minutes, seconds });
    setIsLoading(false);
  };

  return (
    <div className="next-launch">
      {isLoading &&
        <div className='loader-wrap'>
          <h1>STAND BY...</h1>
          <div className="loader">STAND BY...</div>
        </div>
      }
      {!isLoading && !error &&
        <>
          <h1>Upcoming: {launch?.name}</h1>
          <div className="share-buttons">
            <h3>Share on social media:</h3>
            <FacebookShareButton
              url={window.location.href}
              quote='Next launch'
              className="facebook-share"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton
              url={window.location.href}
              title='Next launch'
              className="twiter"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className="container">
            <div className="days">
              <h2>{timeToLaunch.days}</h2>
              <div className="text-wrap">
                <p>Days</p>
              </div>
            </div>
            <div className="hours">
              <h2>{timeToLaunch.hours}</h2>
              <div className="text-wrap">
                <p>Hours</p>
              </div>
            </div>
            <div className="minutes">
              <h2>{timeToLaunch.minutes}</h2>
              <div className="text-wrap">
                <p>Minutes</p>
              </div>
            </div>
            <div className="seconds">
              <h2>{timeToLaunch.seconds}</h2>
              <div className="text-wrap">
                <p>Seconds</p>
              </div>
            </div>
          </div>
          <div style={{margin: 30}}>
            <i onClick={scrollToElement} className="arrow down"/>
          </div>
        </>
      }

      {!isLoading && error &&
        <Error message={error}/>
      }
    </div>
  );
};

UpcomingLaunches.propTypes = {
  scrollToElement: PropTypes.func
};

export default NextLaunch;
