import React, {RefObject, useEffect, useRef, useState} from 'react';
import './App.css';
import NextLaunch from './components/next-launch/NextLaunch';
import UpcomingLaunches from "./components/upcoming-launches/UpcomingLaunches";
import Favorites from "./components/favorites/Favorites";
import FavoritesContext from "./context/FavoritesContext"
import {LaunchData, LaunchDataSimple} from "./components/next-launch/interfaces";
import {getData, removeItem, setItem} from "./_common/helpers/storageHelper";


function App() {
  const [favorites, setFavorites] = useState<LaunchData[]>([]);
  const upcomingRef = useRef<HTMLDivElement>(null); //  Used for scrolling
  const favoritesRef = useRef<HTMLDivElement>(null);  //  Used for scrolling

  useEffect(() => {
    const data: LaunchData[] = getData('launches');
    setFavorites(data);
  }, []);


  const updateFavorites = (item: LaunchData) => {
    // const favoriteData: LaunchData[] = getData('launches');
    const existing = favorites.filter(launch => launch.id === item.id);
    if (existing.length) {
      removeFavoriteItem(item)
    } else {
      addFavoriteItem(item);
    }
  };

  const removeFavoriteItem = (item: LaunchData) => {
    removeItem('launches', item);   //  Remove from storage
    setFavorites(favorites.filter(launch => launch.id !== item.id)) //  Remove from context
  };

  const addFavoriteItem = (item: LaunchData) => {
    setItem('launches', item); // Add to storage
    const newFavoriteArray: LaunchData[] = [...favorites];
    //  Prepare new object
    const newFavoriteObject: LaunchDataSimple = {
      id: item.id,
      name: item.name,
      date_utc: item.date_utc,
      date_precision: item.date_precision,
      launchpad_name: item.launchpad_name,
    };

    // @ts-ignore
    newFavoriteArray.push(newFavoriteObject);
    setFavorites(newFavoriteArray);
  };

  const scrollToElement = (ref: RefObject<HTMLDivElement>) => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };


  return (
    <div className="App">
      <FavoritesContext.Provider value={{favorites, updateFavorites}}>
        <NextLaunch scrollToElement={() => scrollToElement(upcomingRef)}/>
        <div ref={upcomingRef}>
          <UpcomingLaunches scrollToElement={() => scrollToElement(favoritesRef)}/>
        </div>

        <div ref={favoritesRef}>
          <Favorites/>
        </div>
      </FavoritesContext.Provider>

    </div>
  );
}

export default App;
